import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { frFR as datePickersFR } from '@mui/x-date-pickers';
import { frFR as dateGridFR } from '@mui/x-data-grid';
import { StoreContext } from "./store/store";
import Cookies from "js-cookie";
import { LicenseInfo } from '@mui/x-license-pro';
import { NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';

import { logOut, getUserConnected } from "./store/index.service";

import { Header } from './components/Header/Header';
import { Navigation } from './components/Navigation/Navigation';
import { Login } from "./pages/Login/Login";

// PAGES
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Page } from './pages/Page/Page';
import { PageSection } from './pages/Page/PageSection';
import { UpdateSection } from './pages/Page/UpdateSection';
import { UpdateItem } from './pages/Page/Item/UpdateItem';
import { CreateItem } from './pages/Page/Item/CreateItem';
import { Medias } from "./pages/Medias/Medias";

// TEMPLATE
import { Template } from './pages/Template/Template/Template';

// HEADER
import { HeaderEdit } from './pages/Template/Header/HeaderEdit';
import { Footer } from './pages/Template/Footer/Footer';

// SECTION
import { Section } from './pages/Template/Section/Section';

// USER
import { User } from './pages/User/User';


// ------

// MUI
LicenseInfo.setLicenseKey('bbf32a2a6ed875c544e5c62e190b97ceTz04NjE5MCxFPTE3NDE3ODI2NTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const theme = createTheme(
    {
        palette: {
            // primary: {
            //     main: "#ff4733"
            // },
            grey: {
                main: "#999"
            },
        },
        typography: {
            fontFamily: `"Poppins"`
        }
    },
    frFR,
    datePickersFR,
    dateGridFR
);

export const App = () => {

    const { loggedIn, setLoggedIn, setUser } = useContext(StoreContext);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {

        let intervalId = setInterval(() => {

            let log = Cookies.get('loggedIn');
            if(log !== "true")
                setLoggedIn(false);

        }, 1000);

        setMounted(true);

        // unmounted
        return(() => {
            clearInterval(intervalId);
        })

    }, []);

    useEffect(() => {
        
        if(!loggedIn)
            logOut();
        else
            getUserConnected()
                .then(res => {
                    localStorage.setItem("idUser", res.data.idUser)
                    setUser(res.data);
                })

    }, [loggedIn]);

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <div className="App">

                    {!loggedIn ?

                        <Login />

                    : mounted && <>

                        <Header />

                        <div className="container">

                            <Navigation />
                            
                            <div className="content-wrapper">
                                <Routes>
                                    <Route path="/" element={ <Dashboard /> } />
                                    <Route path="/medias" element={ <Medias /> } />

                                    <Route path="/page" element={ <Page /> } />
                                    <Route path="/page/:id/view" element={ <PageSection /> } />
                                    <Route path="/page/:idPage/section/update/:id" element={ <UpdateSection /> } />
                                    <Route path="/page/:idPage/section/update/:idParent/item/:id" element={ <UpdateItem /> } />
                                    <Route path="/page/:idPage/section/:id/:item/create" element={ <CreateItem /> } />
                                    <Route path="/page/:idPage/section/:id/:item/create/:idParent" element={ <CreateItem /> } />
                                    <Route path="/page/:idPage/section/:id/:item/update/:idParent/:idElement" element={ <UpdateItem /> } />

                                    <Route path="/template/template" element={ <Template /> } />

                                    <Route path="/template/section" element={ <Section /> } />

                                    <Route path="/template/header" element={ <HeaderEdit /> } />
                                    <Route path="/template/footer" element={ <Footer /> } />

                                    <Route path="/user" element={ <User /> } />
                                    
                                    
                                </Routes>
                            </div>

                            <NotificationContainer />

                        </div>
                    </>}

                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}