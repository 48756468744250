import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { createItem, getOneItemForm, deleteItem, updateStatusItem, getListItemByTitle } from '../../../store/index.service';
import { FormInputText } from "../../../components/Form/FormInputText";
import { FormAutocomplete } from "../../../components/Form/FormAutocomplete";
import { FormSwitch } from "../../../components/Form/FormSwitch";
import { FormCKEditor } from "../../../components/Form/FormCKEditor";
import { FormButtonSubmit } from "../../../components/Form/FormButtonSubmit";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import { Switch, FormControlLabel, Button } from "@mui/material";
import { Popup } from "../../../components/Popup/Popup";
import { AddField } from "../../../components/Popup/content/AddField";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { ConfirmDelete } from "../../../components/Popup/content/ConfirmDelete";
import { ListField } from "../../../components/Popup/content/ListField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AddImgFromGallery } from "../../../components/AddImgFromGallery/AddImgFromGallery";
import { generateMainForm, generateAsideForm } from "../../../helpers";
import { NotificationManager } from "react-notifications";

const CustomToolbar = ({ idSection, item, idPage, navigate }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <Button size="small" onClick={() => navigate(`/page/${idPage}/section/${idSection}/${item}/create`)} className="btn btn-primary">Ajouter un item</Button>
        </GridToolbarContainer>
    );
}

export const CreateItem = () => {
    let { id, item, idParent, idPage } = useParams();
    const { handleSubmit, control, setValue, watch } = useForm();
    const navigate = useNavigate();
    const [dataForm, setDataForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showPopupAddField, setShowPopupAddField] = useState({ show: false });
    const [showPopupListField, setShowPopupListField] = useState({ show: false });
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [listItem, setListItem] = useState({loading: true, data: []});

    const columns = [
        { field: 'status', headerName: 'Publié', flex: 0.4, type: "boolean", sortable: false, renderCell: (params) => (
            <label className="switch">
                <input type="checkbox" onChange={() => handleStatus(params.row.id)} checked={params.row.status} />
                <span className="slider round"></span>
            </label>
        )},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => navigate(`/page/${idPage}/section/update/${id}/item/${params.row.id}`)}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    const onSubmit = data => {

        setLoading(true);
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if (value && value.type === "image")
                if (value.data)
                    formData.append(key, value.data.id);
                else
                    formData.append(key, null);
            else if (typeof value === "object" && value){
                formData.append(key, JSON.stringify(value));

            } else
                formData.append(key, value);
        }

        for (const [key, value] of Object.entries(dataForm)) {
            if (value && value.type === "list")
                if (key !== "spe-entity")
                    formData.append(key, JSON.stringify(value.data));
        }

        formData.append("itemType", item);
        formData.append("idSection", id);

        if (idParent)
            formData.append("idParent", idParent);

        createItem(formData, id)
            .then(() => {
                setLoading(false);
                NotificationManager.success("Item ajouté");
                navigate(-1);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setErrorMsg("Une erreur est survenue");
            })
    }

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getOneItemForm(item).then(res => {
            setDataForm(res.data[0]);

            if(res.data[0])
                for (const [key, value] of Object.entries(res.data[0])) {
                    if (value && value.type === "image")
                        setValue(key, value);
                    else if (value && value.type === "select")
                        hangleGetListItem(key);
                }

            setValue('status', false);
            
        });

    }  

    const hangleGetListItem = (key) => {
        getListItemByTitle(key).then(res => {
            setListItem({
                loading: false,
                data: res.data ?? []
            });
        });
    }

    const handleForm = (res) => {
        let form = [];

        for (const [key, value] of Object.entries(res)) {
            if(value.type === "string"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if (value.type === "text") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormCKEditor
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
                

            } else if (value.type === "image") {
                form.push(
                    <div className="row-form" key={key}>
                        <AddImgFromGallery
                            value={watch(key) ? watch(key).data : null}
                            chooseImg={(img) => setValue(key, { type: 'image', data: img })}
                        />
                    </div>
                )
            } else if (value.type === "select") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormAutocomplete
                            name={key}
                            label={key}
                            control={control}
                            rules={{ required: false }}
                            loading={listItem.loading}
                            options={listItem.data}
                            getOptionLabel={el => el.fr.title.data}
                            renderOption={(props, option) =>
                                <li {...props} key={option.fr.title.data}>
                                    {option.fr.title.data}
                                </li>
                            }
                        />
                    </div>
                )
            } else if(value.type === "list"){
                form.push(
                    <div className="row-form" style={{ display: 'block' }} key={key}>
                        <label style={{ marginBottom: -20, display: "block", color: "#333" }}>{key}</label>
                        <DataGridPro
                            autoHeight
                            rows={value.data}
                            columns={columns}
                            pageSize={20}
                            className="table"
                            pagination
                            pageSizeOptions={[20, 50, 100]}
                            slots={{
                                toolbar: (params) => <CustomToolbar idSection={id} item={key} idPage={idPage} navigate={navigate} />
                            }}
                        />
                    </div>
                )
            } else if (value.type === "integer") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                            type="number"
                        />
                    </div>
                )
            } else if (value.type === "checkbox") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormSwitch
                            control={control}
                            name={key}
                            label={key}
                            labelPlacement="start"
                            sx={{
                                marginLeft: 0
                            }}
                        />
                    </div>
                )
            }
        } 

        return form;
    }

    const handleDelete = () => {
        deleteItem(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleStatus = (id) => {
        updateStatusItem(id).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="container-full">

            <div className="content">

                <div className="header">
                    <h1>Ajouter un item</h1>
                </div>

                <Breadcrumb
                    links={[
                        { label: "Section", to: `/page/${idPage}/section/update/${id}` },
                        { label: "Ajouter un item" }
                    ]}
                />

                <div className="list-action">
                    <Button variant="contained" onClick={() => setShowPopupAddField({ show: true })}>Ajouter un champ</Button>
                    <Button variant="contained" onClick={() => setShowPopupListField({ show: true })}>Liste des champs</Button>
                </div>

                <div className="form">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="panel">
                            <div className="contents">
                                {handleForm(generateMainForm(dataForm))}
                            </div>

                            <div className="aside-right">

                                {errorMsg &&
                                    <p className="error-msg">
                                        {errorMsg}
                                    </p>
                                }

                                <div className="row-form">
                                    <FormButtonSubmit loading={loading} />
                                </div>

                                <div className="row-form">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={"status"}
                                                control={control}
                                                render={({ field: { onChange, value } }) =>
                                                    <Switch
                                                        checked={!!value}
                                                        onChange={onChange}
                                                    />
                                                }
                                            />
                                        }
                                        label={!!watch("status") ? "Publié" : "Non-publié"}
                                    />
                                </div>

                                {handleForm(generateAsideForm(dataForm))}

                            </div>

                        </div>

                    </form>

                </div>
            </div>

            { showPopupAddField.show &&
                <Popup
                    closePopup={() => setShowPopupAddField({ show: false })}
                    customContent={true}
                >
                    <AddField
                        type = "item"
                        id = {item}
                    />
                </Popup>
            }

            { showPopupListField.show &&
                <Popup
                    closePopup={() => setShowPopupListField({ show: false })}
                    customContent={true}
                >

                    <ListField item = {item} />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"cette page"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

        </div>
    )
}